.navbarCSS{
    padding: 15px 20px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    width: 100vw;
    
}



.navItemCSS{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* .custom-toggler.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  } */

/* 
  @media only screen and (max-width: 600px) {
    .navbarCSS {
        padding: 0px 0px !important;
    }
    .navTitle{
        font-size: 16px !important;
    }

  } */

