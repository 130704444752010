.circular-progressbar {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
  }

  @keyframes progressAnimation {
    from {
      stroke-dashoffset: var(--circumference);
    }
    to {
      stroke-dashoffset: var(--progress);
    }
  }
  
  .circular-progressbar .circle {
    stroke: var(--color);
    fill: transparent;
    stroke-width: 7;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 2s linear;
  }

  .circular-progressbar .circle.animating {
    animation: progressAnimation 3s linear forwards;
  }
  
  .circular-progressbar .bg {
    stroke: #d3d3d3;
  }
  
  .circular-progressbar .percentage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 100px;
    font-size: 20px;
  }
  